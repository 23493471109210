import React, { useEffect, createRef } from "react";
import lottie from "lottie-web";
import { Link } from 'gatsby'
import Header from "../components/header/header"
import { FaAngleRight } from 'react-icons/fa';
import animation from "./../animations/404.json";


const NotFoundPage = () => {
  let animationContainer = createRef();
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: animation
    });
    return () => anim.destroy(); 
  }, []);
  return(
  <div>
  <Header />
  <div></div>
  <div className="animation-container w-1/2 m-auto" ref={animationContainer} />
  <div className="w-full block text-center">
    <div>La page que vous recherchez n'existe pas</div>
    <Link to="/">
      <button className="w-fit shadow-md rounded-full bg-blue-900 text-slate-100 p-2 text-center mt-4 font-semibold">
        <div className="flex items-center">
          <span>retour à la page d'accueil</span><FaAngleRight />
        </div>
      </button>
    </Link>
  </div>
  </div>
  )
}

export default NotFoundPage
